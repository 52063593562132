import { Row, Col, Image, Carousel } from "react-bootstrap";

const growerzImg = require('../assets/growerz-hub.png');
const previewImgs = [
    require('../assets/growerz-hub-preview.png'),
    require('../assets/growerz-hub-preview2.png'),
    require('../assets/growerz-hub-preview3.png')
]

const GrowerzHub = () => {
    return (
        <Row className="bg-leaf bg-dark-green p-4 p-lg-5">
            <Col>
                <Row className="justify-content-center w-md-75 mx-md-auto text-center">
                    <Col>
                        <h1><b>The Growerz Hub</b></h1>
                        <div className="px-3 pt-3 pb-1 bg-darkmode-green my-3 w-100 w-md-75 mx-auto">
                            <p className="fs-5">
                                The Growerz Hub is a game for growing and harvesting plants to earn $THC, a token created by THC Labz. You can use your
                                Growerz to increase your harvesting payout, lower time between waterings, and increase your Growhouse's capacity.
                            </p>
                        </div>
                        <Carousel indicators={false} className="d-none d-md-block">
                            <Carousel.Item>
                                <Image src={previewImgs[0]} alt="Manage Growhouses" rounded className="w-75" />
                                <div className="px-5 pt-3 pb-1 bg-darkmode-green my-3 w-100 w-md-75 mx-auto">
                                    <h3>Manage your Growhouses</h3>
                                    <p>
                                        Buy, upgrade, and manage your growhouses in your very own Growhouse Hub! More Growhouses means more payouts!
                                    </p>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Image src={previewImgs[1]} alt="Pick Growerz" rounded className="w-75" />
                                <div className="px-5 pt-3 pb-1 bg-darkmode-green my-3 w-100 w-md-75 mx-auto">
                                    <h3>Pick your Growerz</h3>
                                    <p>
                                        Select a Grower to help manage your Growhouse by decreasing time between waterings and increasing your Harvest bonus!
                                    </p>
                                </div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <Image src={previewImgs[2]} alt="Dispensary Deals" rounded className="w-75" />
                                <div className="px-5 pt-3 pb-1 bg-darkmode-green my-3 w-100 w-md-75  mx-auto">
                                    <h3>Check our Dispensary Deals</h3>
                                    <p>
                                        Seeds come in a variety of rarities and strains that can be watered quicker and increase your THC harvest!
                                    </p>
                                </div>
                            </Carousel.Item>
                        </Carousel>
                        <Row>
                            <Col>
                                <a className="btn-growerz text-white p-3 br-5" href='https://growerz.thc-labz.xyz' target="_blank" rel="noreferrer">
                                    <img src={growerzImg} alt="Growerz Hub" />
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default GrowerzHub;
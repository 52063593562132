import { Col, Container, Row } from "react-bootstrap";

import Collections from "../components/Collections";
import Growerz from "../components/Growerz";
import GrowerzHub from "../components/GrowerzHub";
import Platform from "../components/Platform";
import Team from "../components/Team";
import TokenInfo from "../components/TokenInfo";
import ToyMachine from "../components/ToyMachine";
import { BackToTop } from "../components/Layout";

const HomePage = () => {
    return (<Container fluid className="bg-clear">
        <Growerz />
        <div className="breaker-bt w-60 mx-auto"></div>
        <GrowerzHub />
        <div className="breaker-bt w-60 mx-auto"></div>
        <TokenInfo />
        <div className="breaker-bt w-60 mx-auto"></div>
        <ToyMachine />
        <div className="breaker-bt w-60 mx-auto"></div>
        <Platform />
        <div className="breaker-bt w-60 mx-auto"></div>
        <Collections />
        <div className="breaker-bt w-60 mx-auto"></div>
        <Team />
        <div className="breaker-bt w-60 mx-auto"></div>
        <BackToTop />

        <Row className="bg-dark-green py-3 px-4 py-lg-3 px-lg-5">
            <Col>
                <p className="pt-3 fs-5 text-center">
                    Stay tuned for more updates as we continue to enhance and expand our ecosystem. Now is the perfect time to jump in our organic community!
                </p>
            </Col>
        </Row>
    </Container>);
}

export default HomePage;